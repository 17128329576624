import {useCallback} from 'preact/hooks';

import type {CompletedEvent} from '~/types/event';

import {useDispatchEvent} from './useDispatchEvent';

export const useWindoidMessageHandler = ({
  windoidRef,
  handleComplete,
}: {
  windoidRef: React.RefObject<Window>;
  handleComplete: (event: CompletedEvent) => void;
  storefrontOrigin?: string;
}) => {
  const dispatchEvent = useDispatchEvent();

  const handleWindoidMessage = useCallback(
    async (payload: MessageEvent) => {
      if (payload.data.type === 'completed') {
        handleComplete(payload.data as any);
        dispatchEvent('completed', payload.data, true);
        windoidRef.current?.close();
      }
    },
    [dispatchEvent, handleComplete, windoidRef],
  );

  return handleWindoidMessage;
};
