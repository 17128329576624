import {useMemo} from 'preact/hooks';

import type {UxModeType} from '~/types/uxMode';
import {isMobileWebview} from '~/utils/browser';

interface Props {
  experiments?: string;
  uxMode: UxModeType;
  defaultUxMode: UxModeType;
}

export const useAdjustedUxMode = ({defaultUxMode, uxMode}: Props) => {
  const adjustedUxMode = useMemo(() => {
    const mobileWebView = isMobileWebview();

    const shouldUseDefault = uxMode === 'windoid' && mobileWebView;

    return shouldUseDefault ? defaultUxMode : uxMode;
  }, [defaultUxMode, uxMode]);
  return adjustedUxMode;
};
